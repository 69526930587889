<template>
  <section fluid>
    <!-- <v-breadcrumbs class="pl-0" :items="breads" large></v-breadcrumbs> -->

    <h3 v-if="$route.params.idHito" class="text-h4 secondary--text">
      {{ nombre_hito }}
    </h3>
    <v-row>
      <v-col cols="12" sm="4">
        <p>Visibilidad:</p>
        <v-switch
          v-model="hito.visibilidad"
          false-value="0"
          true-value="1"
          :label="hito.visibilidad == 1 ? 'Interna' : 'Pública'"
        ></v-switch>
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col cols="12" sm="4">
        <v-text-field
          outlined
          label="Nombre del hito *"
          v-model="hito.nombre"
          :error-messages="nombreError"
          @input="$v.hito.nombre.$touch()"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
          outlined
          label="Tipo hito *"
          v-model="hito.id_tipo_etapa"
          :items="tiposHito"
          item-value="id"
          item-text="nombre"
          :error-messages="tipoEtapaError"
          @blur="$v.hito.id_tipo_etapa.$touch()"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
          outlined
          label="Contenido hito *"
          v-model="hito.id_tipo_configuracion"
          :items="contenidoHito"
          item-value="id"
          item-text="nombre"
          :error-messages="tipoHitoError"
          @blur="$v.hito.id_tipo_configuracion.$touch()"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-textarea
          outlined
          label="Indicaciones de hito"
          v-model="hito.indicaciones"
          :error-messages="indicacionesError"
          @input="$v.hito.indicaciones.$touch()"
        />
      </v-col>
    </v-row>

    <h3 class="text-h5">Perfiles autorizados</h3>

    <v-row class="mt-4">
      <v-col cols="12" sm="2">
        <v-btn @click="showDialog = true" block color="secondary"
          >Agregar perfil</v-btn
        >
      </v-col>
    </v-row>
    <v-data-table
      class="mt-4"
      :headers="headersPerfiles"
      :items="perfiles"
    >
    <template v-slot:body.append="{headers}">
      <tr>
        <td v-for="header in headers">
          <span v-if="header.value === 'leer'" class="d-block text-center">Leidos: {{ contarLeer() }}</span>
          <span v-if="header.value === 'escribir'" class="d-block text-center">Escritos: {{ contarEscribir() }}</span>
        </td>
      </tr>
    </template>

      <template v-slot:[`item.perfil`]="{ item }">
        {{ item.perfil.nombre }}
      </template>

      <template v-slot:[`item.leer`]="{ item }">
        <v-icon color="secondary">{{
          item.leer == 1 ? "mdi-check-circle" : "mdi-circle-outline"
        }}</v-icon>
      </template>

      <template v-slot:[`item.escribir`]="{ item }">
        <v-icon color="secondary">{{
          item.escribir == 1 ? "mdi-check-circle" : "mdi-circle-outline"
        }}</v-icon>
      </template>

      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="setUpdate(item)"
              dark
              icon
              v-on="on"
              v-bind="attrs"
              color="secondary"
            >
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </template>
          <span>Cambiar permisos</span>
        </v-tooltip>

        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="eliminar(item)"
              dark
              icon
              v-on="on"
              v-bind="attrs"
              color="secondary"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Eliminar perfiles</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-alert
      dense
      class="mt-4"
      text
      v-if="rolesError.length > 0"
      type="error"
      transition="slide-x-transition"
    >
      {{ rolesError[0] }}
    </v-alert>

    <v-row align="center" class="mt-8 mx-1">
      <v-btn @click="$router.back()" outlined color="secondary">Volver</v-btn>
      <v-btn @click="saveHito" color="secondary white--text" class="mx-4"
        >Guardar</v-btn
      >
    </v-row>

    <v-dialog class="modal" v-model="showDialog" max-width="650" persistent>
      <v-card max-width="650">
        <v-btn
          @click="(showDialog = false), clearInfo()"
          icon
          color="secondary"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
        <v-card-text>
          <section>
            <v-row>
              <p class="text-h5 secondary--text mx-4">Perfil</p>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  label="Perfil a agregar *"
                  :items="perfilesSelect"
                  item-value="id"
                  item-text="nombre"
                  :disabled="editable"
                  outlined
                  return-object
                  v-model="perfil_seleccionado"
                  :error-messages="perfilError"
                  @blur="$v.perfil_seleccionado.$touch()"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <p class="secondary--text mb-0">Permisos</p>
                <v-checkbox
                  label="Lectura"
                  color="secondary"
                  hide-details
                  v-model="leer"
                  :value="leer"
                  false-value="0"
                  true-value="1"
                ></v-checkbox>
                <v-checkbox
                  label="Escritura"
                  color="secondary"
                  hide-details
                  v-model="escribir"
                  false-value="0"
                  true-value="1"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row align="center" class="mt-8">
              <v-btn
                @click="(showDialog = false), clearInfo()"
                outlined
                color="secondary"
                >Cancelar</v-btn
              >
              <v-btn
                @click="addParticipante"
                color="secondary white--text"
                class="mx-4"
                >{{ editable ? "Editar" : "Agregar" }}</v-btn
              >
            </v-row>
          </section>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ConfirmationDialogComponent
      :show="deletePerfil"
      title="¿Desea eliminar este perfil?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="deletePerfil = false"
      @confirm="deletePerfilHito()"
    />
  </section>
</template>

<script>
import { helpers, required } from "vuelidate/lib/validators";
import ConfirmationDialogComponent from "../../components/ConfirmationDialogComponent.vue";
const letter = helpers.regex(
  "letter",
  /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9,.:/#$%()"" -]*$/
);

export default {
  name: "configuracionHitoView",
  components: { ConfirmationDialogComponent },
  data: () => ({
    idModalidad: null,
    headersPerfiles: [
      {
        text: "Perfil",
        value: "perfil",
        align: "center",
        width: "40%",
      },
      {
        text: "Lectura",
        value: "leer",
        align: "center",
        width: "20%",
      },
      {
        text: "Escritura",
        value: "escribir",
        align: "center",
        width: "20%",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        width: "20%",
      },
    ],
    perfiles: [],
    showDialog: false,
    id_perfil: null,
    perfilesSelect: [],
    tiposHito: [],
    contenidoHito: [
      {
        id: 1,
        nombre: "Documentos",
      },
      {
        id: 2,
        nombre: "Texto",
      },
    ],
    hito: {
      nombre: null,
      visibilidad: 2,
      indicaciones: null,
      id_tipo_etapa: null,
      id_tipo_configuracion: null,
      roles_autorizados: [],
    },
    perfil_seleccionado: null,
    leer: 0,
    escribir: 0,
    nombre_hito: null,
    editable: false,
    id_to_edit: null,
    deletePerfil: false,
  }),
  validations: {
    hito: {
      nombre: { required, letter },
      indicaciones: { letter },
      id_tipo_etapa: { required },
      id_tipo_configuracion: { required },
    },
    perfil_seleccionado: { required },
    perfiles: { required },
  },
  computed: {
    nombreError() {
      const errors = [];
      if (!this.$v.hito.nombre.$dirty) return errors;
      !this.$v.hito.nombre.required && errors.push("Nombre es requerido");
      !this.$v.hito.nombre.letter &&
        errors.push("Nombre no puede contener caracteres especiales");
      return errors;
    },
    indicacionesError() {
      const errors = [];
      if (!this.$v.hito.indicaciones.$dirty) return errors;
      !this.$v.hito.indicaciones.letter &&
        errors.push(
          "Indicaciones de hito no puede contener caracteres especiales"
        );
      return errors;
    },
    tipoEtapaError() {
      const errors = [];
      if (!this.$v.hito.id_tipo_etapa.$dirty) return errors;
      !this.$v.hito.id_tipo_etapa.required &&
        errors.push("Tipo hito es requerido");
      return errors;
    },
    tipoHitoError() {
      const errors = [];
      if (!this.$v.hito.id_tipo_configuracion.$dirty) return errors;
      !this.$v.hito.id_tipo_configuracion.required &&
        errors.push("Contenido hito es requerido");
      return errors;
    },
    rolesError() {
      const errors = [];
      if (!this.$v.perfiles.$dirty) return errors;
      !this.$v.perfiles.required &&
        errors.push("Debe agregar al menos un perfil");
      return errors;
    },
    perfilError() {
      const errors = [];
      if (!this.$v.perfil_seleccionado.$dirty) return errors;
      !this.$v.perfil_seleccionado.required &&
        errors.push("Debe seleccionar un perfil");
      return errors;
    },
    breads() {
      return [
        {
          text: "Modalidades",
          disabled: false,
          href: "/configuracion-proceso-compra",
        },
        {
          text: "Nombre modalidad",
          disabled: false,
          href: `/configuracion-proceso-compra/${this.$route.params.idModalidad}/detalle`,
        },
        {
          text: this.$route.params.idHito ? this.nombre_hito : "",
          disabled: true,
        },
      ];
    },
  },
  methods: {
    async addParticipante() {

      this.$v.perfil_seleccionado.$touch();

      if (!this.$v.perfil_seleccionado.$invalid) {
        if (this.$route.params.idHito) {
          if (this.editable) {
            const { status } =
              await this.services.PacProcesos.putParticipanteModalidad(
                this.id_to_edit,
                {
                  leer: this.leer,
                  escribir: this.escribir,
                }
              ).catch(() => {

              });
            if (status === 200) {
              setTimeout(() => {
                this.getHito();
              }, 500);
            }
          } else {
            const { status } =
              await this.services.PacProcesos.postParticipanteModalidad(
                this.hito.id,
                {
                  id_perfil: this.perfil_seleccionado.id,
                  leer: this.leer,
                  escribir: this.escribir,
                }
              ).catch(() => {

              });
            if (status === 201) {
              this.getHito();
            }
          }
        } else {
          let objParticipante = {
            id: this.perfil_seleccionado.id + 1,
            id_perfil: this.perfil_seleccionado.id,
            perfil: this.perfil_seleccionado,
            leer: this.leer,
            escribir: this.escribir,
          };
          if (this.editable) {
            const row = (element) => element.id == this.id_to_edit;
            let index = this.perfiles.findIndex(row);

            Object.assign(this.hito.roles_autorizados[index], objParticipante);

            Object.assign(this.perfiles[index], objParticipante);
          } else {
            this.hito.roles_autorizados.push(objParticipante);
            this.perfiles.push(objParticipante);
          }
        }
        this.clearInfo();
        this.showDialog = false;
      }

    },
    setUpdate(item) {
      this.editable = true;
      this.perfil_seleccionado = item.perfil;
      this.leer = item.leer ? "1" : "0";
      this.escribir = item.escribir ? "1" : "0";
      this.id_to_edit = item.id;

      this.showDialog = true;
    },
    clearInfo() {
      this.perfil_seleccionado = null;
      this.leer = 0;
      this.escribir = 0;
      this.editable = false;
      this.$v.$reset();
    },
    async getPerfilesList() {
      const { status, data } = await this.services.Usuarios.getPerfilRol({
        roles: false,
        paginacion: false,
      });
      if (status == 200) {
        this.perfilesSelect = data;
      }
    },
    async saveHito() {

      this.$v.hito.$touch();
      this.$v.perfiles.$touch();
      if (this.$v.$invalid) {
        this.temporalAlert({
          show: true,
          type: "error",
          message: "Ingresar todos los datos requeridos",
        });

      }

      if (!this.$v.hito.$invalid && !this.$v.perfiles.$invalid) {
        let response = null;

        if (this.$route.params.idHito) {
          response = await this.services.PacProcesos.actualizarHito(
            this.$route.params.idHito,
            this.hito
          ).catch(() => {

          });
        } else {
          response = await this.services.PacProcesos.postNuevoHito(
            this.$route.params.idModalidad,
            this.hito
          ).catch(() => {

          });
        }

        if (response?.status == 201 || response?.status == 200) {
          this.temporalAlert({
            show: true,
            message: `Hito ${
              this.$route.params.idHito ? "actualizado" : "guardado"
            } con éxito`,
            type: "success",
          });

          this.$router.back();
        }
      }
    },
    async getHito() {

      const { status, data } = await this.services.PacProcesos.getHito(
        this.$route.params.idHito
      ).catch(() => {

      });

      if (status == 200) {
        this.nombre_hito = data?.nombre;
        this.hito.nombre = data?.nombre;
        this.hito.id = data?.id;
        this.hito.visibilidad = data?.visibilidad;
        this.hito.id_tipo_etapa = data?.id_tipo_etapa;
        this.hito.id_tipo_configuracion = data?.id_tipo_configuracion;
        this.hito.indicaciones = data?.indicaciones;
        this.perfiles = data?.ParticipantesEtapaProcesos?.map((col) => ({
          ...col,
          perfil: col.Perfil,
        }));
      }

    },
    eliminar(item) {
      this.id_perfil = item.id;
      this.deletePerfil = true;
    },
    async deletePerfilHito() {

      const response =
        await this.services.PacProcesos.eliminarPerfilesAutorizados(
          this.id_perfil
        )
      if (response) {
        this.temporalAlert({
          show: true,
          message: "Se eliminó el perfil con éxito",
          type: "success",
        });
      }

      this.deletePerfil = false;
      this.perfiles = this.perfiles.filter((i) => i.id !== this.id_perfil);
    },
    async getTipoHitos() {
      const { status, data } = await this.services.PacProcesos.getTipoHitos();

      if (status == 200) {
        this.tiposHito = data;
      }
    },
    // Método para contar elementos con item.leer == true
    contarLeer() {
      return this.perfiles.filter(item => item.leer === true).length;
    },
    // Método para contar elementos con item.escribir == true
    contarEscribir() {
      return this.perfiles.filter(item => item.escribir === true).length;
    }
  },
  created() {
    this.getPerfilesList();
    this.getTipoHitos();

    if (this.$route.params.idHito) {
      this.getHito();
    }
  },
};
</script>
